.rock {
  position: absolute;
  margin: 0;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: moveUp 5s;
}

.rockContainer {
    height: 100vh;
}

h2 {
  color: black;
  margin-bottom: 10px;
  user-select: none;
}

.image {
  border: solid 10px black;
  width: 40vh;
  border-radius: 5px;
  margin-top: 10px;
}

@keyframes moveUp {
  0% {
    top: 50%;
  }
  90% {
    top: 50%;
  }
  100% {
    top: 35%;
  }
}

@media (max-width:480px) {
    .image {
        width: 70vw;
    }
    h2 {
        font-size: 20px;
    }
}
