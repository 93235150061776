.shareBtn {
  font-size: 20px;
  font-family: "Grand Hotel", cursive;
  border: none;
  border-radius: 32px;
  padding: 10px 15px;
  background-color: #29524A;
  color: white;
  margin: 0;
  width: 200px;
  transition: ease-in-out 0.1s;
  line-height: 1.2;
}

.shareBtn:hover {
  cursor: pointer;
  background-color: white;
  color: #0d1b1e;
}



@media (max-width: 480px) {
  .shareBtn {
    font-size: 16px;
    width: 150px;
  }
}