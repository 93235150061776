.container {
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  /* transform: translateX(-50%); */
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  row-gap: 20px;
}

h1 {
  font-family: "Koulen", cursive;
  font-size: 32px;
  margin: 0;
  padding: 0;
}

input {
  font-family: 'Grand Hotel', cursive;
  font-style: italic;
  font-size: 28px;
  padding: 10px;
  border-radius: 30px;
  width: 60vw;
  border: none;
  margin: 0;
}

input:focus {
  outline: 2px solid black;
}

.introShare {
  margin-top: 200px;
}

.nudgeContainer {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.textElement {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: auto;
}

.textElement img {
  height: 32px;
}

.textElement p {
  padding: 0;
  margin: 0;
}


@media (max-width: 400px) {
  input {
    font-size: 24px;
  }
  h1 {
    font-size: 28px;
  }
}