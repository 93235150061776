.footerContainer {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateX(-50%);
  width: 100%;
  padding: 10px;
}

.footer {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  column-gap: 5%;
  border-radius: 32px;
  padding: 20px;
}

.coffee {
  margin: 0;
  background-color: #5f7fff;
  font-size: 20px;
  font-family: "Grand Hotel", cursive;
  border: none;
  border-radius: 32px;
  padding: 10px 15px;
  color: white;
  width: 200px;
  transition: ease-in-out 0.1s;
  text-decoration: none;
  line-height: 1.2;
  display: inline-block;
  box-sizing: border-box;
}


.tryAgainBtn {
  background: none;
  border: 2px solid #29524A;
  /* border-style: inset; */
  /* border: none; */
  color: #29524A;
  transition: ease-in-out 0.1s;
  border-radius: 32px;
  padding: 10px 15px;
  font-size: 20px;
  font-family: "Grand Hotel", cursive;
  line-height: 1.2;
  /* box-sizing: border-box; */
  width: 200px;
}

.tryAgainBtn:hover {
  background-color: white ;
  color: #0d1b1e;
  border: 2px solid white;
}

.coffee:hover {
  cursor: pointer;
  background-color: white;
  color: #0d1b1e;
}

.websitePlug{
  font-size: 12px;
  color: #0d1b1e;
}

.websitePlug a {
  color: #0d1b1e;
}

@media (max-width: 1000px) {
  .footer {
    bottom: 10%;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
  }
}

@media (max-width: 480px) {
  .footer {
    width: 90%;
    row-gap: 10px;
    font-size: 16px;
  }
  .coffee {
    font-size: 16px;
    width: 150px;
  }
  .tryAgainBtn {
    font-size: 16px;
    width: 150px;
  }
}